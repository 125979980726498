import React  from 'react'
import Layout from '../components/layout' 
import { Container, Row, Col } from 'reactstrap'
import banner from '../images/meet-team-banner.jpg'
import { TeamDisplayPropsType, TeamDisplay } from '../data/jsonData'
import marc from '../images/mark.jpg'
import james from '../images/james.jpg'
import megan from '../images/megan.jpg'
import anne from '../images/anne.jpg'
import userImage from '../images/joanne.jpg' 
import { BookAnAppoinment } from '../components/book0an-apoinment'
import { seoData } from '../data/seo-data'

export const teams: { [nama: string]: TeamDisplayPropsType } = {
    marc: {
        name: 'MARC RUMMEL',
        position: 'Managing Director & Shareholder',
        pic: marc,
        content: (
            <>
                <p>Marc has been a Managing Director of Solutions Financial Consultants for the past six years. He has worked in the Financial Sector for the past 12 years and is committed to providing proven wealth management, retirement and protection strategies. Marc is keen to establish a working relationship with our clients and assist in any financial matters they may have.</p>
                <p>He is also actively responsible for the development & implementation of strategies which include cashflow management, risk insurance, superannuation & retirement income streams. His time is also spent exploring new strategies which can be implemented to assist our clients to achieve their personal financial goals.</p>
                <p>Marc is a proud father with two young boys, Michael and Jack who enjoys taking them to the park and watching movies with them. In Marc’s spare time, he is a keen Brazilian jiu-jitsu practitioner and trains in this martial art as much as he can.</p>
            </>
        ),
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    james: {
        name: 'JAMES TAYLOR',
        position: 'Investment Consultant',
        pic: james,
        content: (
            <>
                <p>James has been working in the financial sector for over 17 years and is an integral part of decision making in the direction of the business. James has extensive experience as a Financial Analyst and is responsible for the research and composition of investment strategies.</p>
                <p>When James is not in the office, he is generally training for some crazy endurance event. Having completed the Melbourne Ironman, North Face (50km trail run), Three Peaks (235k bid ride), he is always on the lookout for the next challenge. If it is not working or training, he is having a beer or two, or watching a movie.</p>
            </>
        ),
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    megan: {
        name: 'MEGAN STEVENS',
        position: 'Financial Controller',
        pic: megan,
        content: (
            <>
                <p>Megan’s responsibilities as Financial Controller includes the preparation of tax, creating and maintaining the Company’s accounting system, processing & recording transactions and payroll. Regardless of her friendly and generous nature, she is also straight down the line when it comes to important business issues, which helps when keeping the Directors in line.</p>
                <p>In Megan’s spare time, her two boys keep her very busy with school & sporting activities. Her other interests are food & cooking and enjoys reading reviews on the hottest new restaurants to try.</p>
            </>
        ),
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    anne: {
        name: 'ANNE NOLAN',
        position: 'Client Services Manager',
        pic: anne,
        content: (
            <>
                <p>Anne joined the team in January 2015 bringing 18 years experience of customer service and administrative experience. She maintains exceptional service with our clientele and enjoys the close relationships between the team at Solutions, the development of which allows a true understanding of the individuals and their goals.</p>
                <p>Anne and her husband, Jack, have two daughters – Bonnie & Mietta. She enjoys spending time with family, watching sporting events and playing tennis.</p>
            </>
        ),
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    josephine: {
        name: 'JOSEPHINE RUMMEL',
        position: 'Administration Officer',
        pic: userImage,
        content: (
            <>
                <p>Josephine is an intergral part of the Solutions Financial Consultants team. She supports the team by delivering excellent administrative service to our clients and her attention to detail is second to none.</p>
                <p>When Josephine is not working for Solutions, she is spending time with her husband Marc raising their two very active sons Michael and Jack. Josephine also enjoys training in yoga & pilates.</p>
            </>
        ),
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
}

const MeetTheTeam = () => { 
    return (
        <Layout
            seo={{ title: seoData.meetTheTeam.title, description: seoData.meetTheTeam.description, keyword: '' }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            setUid={() => {} }
        >
             

            <section style={{ margin: '50px 0' }}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={7}>
                            <h3 className="header-font" style={{ textAlign: 'center' }}>Meet the experienced financial experts   who will help build and protect your wealth.</h3>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={2} style={{ paddingBottom: 30, paddingTop: 60 }} > <div style={{ minHeight: '100%', backgroundColor: '#ffd400' }}></div> </Col>
                        <TeamDisplay {...teams.marc} col={8} />
                        <Col md={2} style={{ paddingBottom: 30, paddingTop: 60 }} > <div style={{ minHeight: '100%', backgroundColor: '#ffd400' }}></div> </Col>
                    </Row>
                    <Row className="justify-content-md-center" >
                        <TeamDisplay {...teams.james} col={6} />
                        <TeamDisplay {...teams.megan} col={6} />
                        <Col md={6}>
                            <div style={{ height: 30, marginBottom: 30, backgroundColor: '#ffd400' }}></div>
                        </Col>
                        <Col md={6}>
                            <div style={{ height: 30, marginBottom: 30, backgroundColor: '#ffd400' }}></div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <TeamDisplay {...teams.anne} col={6} />
                        <TeamDisplay {...teams.josephine} col={6} />
                        <Col md={6}>
                            <div style={{ height: 30, marginBottom: 30, backgroundColor: '#ffd400' }}></div>
                        </Col>
                        <Col md={6}>
                            <div style={{ height: 30, marginBottom: 30, backgroundColor: '#ffd400' }}></div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <BookAnAppoinment />
        </Layout>
    )
}

const FreeConsulTationBanner = () => {
    return (
        <>
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: 600 }}>
                <Container>
                    <Row className="justify-content-md-center" style={{ paddingBottom: 50, textAlign: "center" }}>
                        <Col md={6}>
                            <div style={{ marginTop: '50%', background: 'rgba(1, 107, 146, 0.75)', padding: '10px 0' }}>
                                <h3 className="header-font" style={{ color: '#fff', fontSize: '5em', marginBottom: 0, width: 'auto' }}>Meet The Team </h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ backgroundColor: 'rgb(1, 107, 146)', minHeight: 50 }}>

            </div>
        </>
    )
}

export default MeetTheTeam